/* we can use here all scss vars from bulma.scss 

mobile: up to 768px
tablet: from 769px
desktop: from 1024px
widescreen: from 1216px
fullhd: from 1408px

*/

#wrapper {
  height: auto;
  overflow-x: hidden;
  position: relative;
}

#page-logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 99;
  pointer-events: none;
}

#page-logo img {
  width: 150px;
  height: auto;
  position: relative;
  margin: 10px;
  pointer-events: all;
}

@media screen and (max-width: 1023px) {
  #page-logo img {
    width: 120px;
    margin: 10px;
  }
}

@media screen and (max-width: 512px) {
  #page-logo img {
    width: 100px;
    margin: 5px;
  }
}

@media screen and (max-width: 375px) {
  #page-logo img {
    width: 80px;
    margin: 0 0 0 5px;
  }
}

a.button {
  text-decoration: none !important;
}

.has-bg-img {
  background-size: cover;
  background-position: center center;
}

.is-grayscale {
  filter: grayscale(100%);
}

.is-size-0 {
  font-size: $size-0;
}

@media screen and (max-width: 769px) {
  .is-size-0 {
    font-size: $size-1;
  }
}

@media screen and (max-width: 512px) {
  .is-size-0 {
    font-size: $size-2;
  }
}

.has-second-font {
  font-family: "Kadwa", serif;
}

.is-parallax {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1;
}

.parallax-background {
  transform: translateZ(-10px) scale(2);
}

.parallax-foreground {
  transform: translateZ(-5px) scale(1.5);
}

.parallax-background,
.parallax-foreground {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  z-index: -1;
}

@media screen and (max-width: 512px) {
  .is-parallax {
    height: 50%;
  }

  .parallax-background {
    transform: translateZ(-10px) scale(3) translateY(-10%);
  }

  .parallax-foreground {
    transform: translateZ(-5px) scale(2) translateY(-20%);
  }
}

.is-transparent {
  background-color: transparent !important;
}

.has-hoverfx {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

.has-hoverfx:hover {
  opacity: 0.8;
}

.in-second-font {
  font-weight: normal;
  font-family: "Kadwa", serif;
}

.in-fancy-font {
  font-weight: normal;
  font-family: "Shrikhand", cursive;
}

.big-item-textlogo {
  width: 100%;
  position: relative;
  z-index: 9;
  font-size: 8em;
  font-weight: bold;
  color: $secondary;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.8);
}

.has-textshadow {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

path {
  fill: transparent;
}

.has-z-index-0 {
  z-index: 0 !important;
}

.has-z-index-1 {
  z-index: 1 !important;
}

.has-z-index-2 {
  z-index: 2 !important;
}

.has-z-index-3 {
  z-index: 3 !important;
}

.has-z-index-4 {
  z-index: 4 !important;
}

.has-z-index-5 {
  z-index: 5 !important;
}

.has-opacity-0 {
  opacity: 0 !important;
}

.has-opacity-1 {
  opacity: 0.1 !important;
}

.has-opacity-2 {
  opacity: 0.2 !important;
}

.has-opacity-3 {
  opacity: 0.3 !important;
}

.has-opacity-4 {
  opacity: 0.4 !important;
}

.has-opacity-5 {
  opacity: 0.5 !important;
}

.has-opacity-6 {
  opacity: 0.6 !important;
}

.has-opacity-7 {
  opacity: 0.7 !important;
}

.has-opacity-8 {
  opacity: 0.8 !important;
}

.has-opacity-9 {
  opacity: 0.9 !important;
}

.has-opacity-10 {
  opacity: 1 !important;
}

.has-backdrop-gfx::after {
  content: "";
  width: 110%;
  height: 110%;
  border-radius: 100%;
  position: absolute;
  background-color: $secondary;
  bottom: -50px;
  right: -50px;
  z-index: 0;
  opacity: 0.2;
}

@media screen and (max-width: 512px) {
  .has-backdrop-gfx::after {
    width: 102%;
    height: 102%;
    bottom: -20px;
    right: -20px;
  }
}

.has-white-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 0, 0, 0) 66%
  );
}

.has-background-image {
  background-size: cover;
  background-position: center center;
}

.has-dark-opacity-bg {
  background-color: rgba(0, 0, 0, 0.8);
}

.overlay-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.navbar-item img {
  max-height: 100% !important;
}

.section-header {
  margin-bottom: 2rem;
}

a.navbar-item {
  text-transform: uppercase;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

// Artists

.artist-name {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.next-up-icon {
  display: none;
}

.next-up .next-up-icon {
  display: inline-block;
}

// Gallery

.album-meta {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
